export default {
  SET(state, data) {
    state.rentalSaleOrder = { ...data }
  },
  SET_ITEMS(state, data) {
    state.assetItems = data
  },
  SET_RE_RENDER_COMPONENT_KEY(state) {
    state.reRenderComponentKey += 1
  },
}
