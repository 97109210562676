<template>
  <scan
    :asset-item-serial-numbers="assetItemSerialNumbers"
    @getValue="handleCheckBySerialNumber"
  >
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Order Items') }}
      </h2>
      <span>
        <feather-icon
          icon="LWarningIconBlue"
          class="featherIcon"
          size="30"
        />
        {{ $t('To start the pick process, press pick or uncheck the items not to be picked.') }}</span>
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Cameras + Lenses -test') }}
      </h2>
      <l-table-list-collector
        ref="lTableRef"
        :table-columns="tableColumns"
        :module-name="MODULE_NAME"
        :fetched-data="rentalOrder.inventory_item"
        :is-searchable.camel="false"
      >
        <template #head(action)>
          <div
            class="d-flex p-0"
          >
            <b-form-checkbox
              :indeterminate="isIndeterminate && !pickedAllItems"
              :checked="pickedAllItems"
              @change="handleCheckAllItems"
            />
          </div>
        </template>
        <template #cell(action)="{ data: { item }}">
          <div
            class="d-flex pl-1"
          >
            <b-form-checkbox
              :checked="item.picked"
              @change="handleCheckItem($event, item.id)"
            />
          </div>
        </template>
        <template #cell(state)="{ data: { item }}">
          <div
            class="d-flex pl-1"
          >
            <state-of-asset
              :data="item"
              :with-label="false"
            />
          </div>
        </template>
        <template #cell(sku)="{ data: { item }}">
          {{ item.product.sku }}
        </template>
        <template #cell(name)="{ data: { item }}">
          {{ item.product.name }}
        </template>
        <template #cell(location)="{ data: { item }}">
          {{ item.location.level_name }}
        </template>
        <template #cell(status)="{ data: { item }}">
          <div
            v-if="item.status === assetStates.PICKED.code"
            class="d-flex pl-2"
          >
            <feather-icon
              icon="LApproved"
              size="20"
            />
          </div>
          <div
            v-else
            class="d-flex pl-2"
          >
            {{ '&mdash;' }}
          </div>
        </template>
      </l-table-list-collector>
    </div>
  </scan>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { assetStates } from '@/enum/asset-statuses'
import Scan from '@/views/components/Scan/Scan.vue'
import { find, map } from 'lodash'

export default {
  name: 'RentalSalesOrderItems',
  components: {
    Scan, StateOfAsset, BFormCheckbox, LTableListCollector,
  },
  computed: {
    assetStates() {
      return assetStates
    },
    isIndeterminate() {
      return this.assetsItem.some(item => item.picked)
    },
    pickedAllItems() {
      return this.assetsItem.every(item => item.picked)
    },
    assetsItem() {
      return this.$store.state[this.MODULE_NAME].assetItems
    },
    rentalOrder() {
      return this.$store.state[this.MODULE_NAME].rentalSaleOrder
    },
    assetItemSerialNumbers() {
      return map(this.assetsItem, 'serial_number')
    },
  },
  methods: {
    handleCheckAllItems(checked) {
      const checkedItems = this.assetsItem.map(item => ({ ...item, picked: checked }))
      this.$store.commit(`${this.MODULE_NAME}/SET_ITEMS`, checkedItems)
    },
    handleCheckItem(checked, id) {
      const checkedItems = this.assetsItem.map(item => (item.id === id ? { ...item, picked: checked } : item))
      this.$store.commit(`${this.MODULE_NAME}/SET_ITEMS`, checkedItems)
    },
    handleCheckBySerialNumber(serialNumber) {
      const checkedItems = find(this.assetsItem, { serial_number: serialNumber })
      if (!checkedItems) return
      this.handleCheckItem(!checkedItems.picked, checkedItems.id)
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
