<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <b-button
      class="cancelBtn font-medium-1 font-weight-bolder"
      variant="outline-primary"
      @click="$router.push({ name: 'home-warehouse-view' })"
    >
      {{ $t('Back to List') }}
    </b-button>
    <div class="d-flex">
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        @click="printAppContent"
      >
        <feather-icon
          icon="LPrintIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t('Print Pick List') }}</span>
      </b-button>

      <b-button
        v-if="assetsItemPicked"
        variant="success"
        class="d-flex  justify-content-center ml-2"
        style="width: 170px; height: 40px;"
        :disabled="!someAssetsPicked || isLoading"
        @click="handleSubmit(PACKED_ACTION_STATE)"
      >
        <feather-icon
          icon="LBoxIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t(PACKED_ACTION_STATE) }}</span>
      </b-button>
      <b-button
        v-else
        variant="success"
        class="d-flex  justify-content-center ml-2"
        style="width: 170px; height: 40px;"
        :disabled="!someAssetsPicked || isLoading "
        @click="handleSubmit(PICKED_ACTION_STATE)"
      >
        <feather-icon
          icon="LPickIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t(PICKED_ACTION_STATE) }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import { printAppContent } from '@/views/main/orders/components/helpers'
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'
import { assetStates } from '@/enum/asset-statuses'

export default {
  name: 'RentalSalesFormBottom',
  components: { BButton },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    assetStates() {
      return assetStates
    },
    // readyForPick() {
    //   return this.getServiceInformation.state === 2
    // },
    getRentalSalesInformation() {
      return this.$store.state[this.MODULE_NAME].rentalSaleOrder
    },
    assetsItem() {
      return this.$store.state[this.MODULE_NAME].assetItems
    },
    someAssetsPicked() {
      return this.assetsItem.some(item => item.picked)
    },
    assetsItemPicked() {
      return false
      // return this.getRentalSalesInformation.inventory_item_damaged.pick_at
    },
  },
  methods: {
    handleSubmit(action) {
      const payload = {
        order_product_ids: this.assetsItem.filter(item => item.picked).map(item => item.id),
        order_id: this.getRentalSalesInformation.id,
      }
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/${action === this.PICKED_ACTION_STATE ? 'pickUpRentalSalesOrder' : 'packUpRentalSalesOrder'}`, payload)
        .then(() => {
          this.$store.commit(`${this.MODULE_NAME}/SET`, {})
          this.$store.commit(`${this.MODULE_NAME}/SET_ITEMS`, [])
          this.$store.commit(`${this.MODULE_NAME}/SET_RE_RENDER_COMPONENT_KEY`)
        }).catch(err => {
          this.errorNotification(this, err)
        }).finally(() => {
          this.isLoading = false
        })
    },
  },
  setup() {
    const {
      MODULE_NAME, PICKED_ACTION_STATE,
      PACKED_ACTION_STATE,
    } = config()

    return {
      printAppContent,
      MODULE_NAME,
      PICKED_ACTION_STATE,
      PACKED_ACTION_STATE,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
